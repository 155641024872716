<template>
  <Chat isCompany />
</template>

<script>
import Chat from "@/views/dashboard/Chat";

export default {
  components: { Chat }
};
</script>

<style lang="scss" scoped></style>
